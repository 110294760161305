import * as React from 'react';
import { Icon, Header, Message, Transition } from 'semantic-ui-react';
import './ConnectionStatus.css';
import { useContext } from "react";
import { IDriverProvider, DriverContext } from "../../context/DriverProvider";


const ConnectionStatus = ({ visible, existTrip }: { visible: boolean, existTrip:boolean }) => {
    const { isDark } = useContext<IDriverProvider>(DriverContext);
    return (
        <Transition animation='scale' duration={500} visible={visible || !existTrip}>
            <Message
                className='connection-status'
                color={isDark ? 'grey' : 'black'}
                size='small'
            >
                <Header as='h4' color={isDark ? 'black' : 'red'}>
                    <Icon name='warning sign' />
                    { visible ?
                        <Header.Content>This trip is already out of date or completed</Header.Content>
                        : !existTrip &&
                        <Header.Content>This trip does not exist</Header.Content>
                    }
                </Header>
            </Message>
        </Transition>
    )
};

export default ConnectionStatus;
