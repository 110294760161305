import {ApolloClient, ApolloLink, gql, HttpLink, InMemoryCache } from 'apollo-boost';
// import { onError } from "@apollo/client/link/error"; // future implementation
// import { from } from "@apollo/client"; // future implementation
import {getGraphqlErrorMessage, getSchemaType, GqlSchema} from "../helpers/graphql.helpers";


const API = process.env.API;
const DRIVER_KEY = process.env.DRIVER_KEY;

const httpPublicLink = new HttpLink({uri: API + '/public'});
const httpPrivateLink = new HttpLink({uri: API + '/driver_mobile'});

/* future implementation */
// const errorLink = onError(({ graphQLErrors, networkError}) => {
//     if (graphQLErrors)
//        graphQLErrors.forEach(({ message }) =>
//          console.log(`[GraphQL error]: ${message}`));
    
//     if (networkError) console.log(`[Network error]: ${networkError}`);    
// });

export abstract class BaseModel {

    publicClient(): ApolloClient<any> {
        return new ApolloClient({
            link: httpPublicLink,
            cache: new InMemoryCache(),

            defaultOptions: {
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'ignore',
                },
            },
        });
    }

    onboardingClient(): ApolloClient<any> {
        const authLink = new ApolloLink((operation, forward) => {
            const token = localStorage.getItem('jwt-driver');
            operation.setContext({
                headers: {
                    'Authorization-Public': token
                },
            });
            return forward(operation);
        });

        return new ApolloClient({
            link: authLink.concat(httpPublicLink),
            cache: new InMemoryCache(),
        });
    }
    onboardingClientPrivate(): ApolloClient<any> {
        const authLink = new ApolloLink((operation, forward) => {
            const token = localStorage.getItem('driver-token');
            operation.setContext({
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            return forward(operation);
        });

        return new ApolloClient({
            link: authLink.concat(httpPrivateLink),

            /* future implementation */
            // link: from([errorLink, authLink.concat(httpPrivateLink) as ApolloLink]),
            cache: new InMemoryCache(),
        });
    }

    driverQuery(gqlSchema, variables = undefined): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const schema = gql` ${gqlSchema}`;
            try {
                const response: any = await this.onboardingClient()
                    .query({query: schema, variables: variables,});
                resolve(await this.resolveRequest(response, {schema, variables}, false));
            }
            catch (error) {
                if (getGraphqlErrorMessage(error) === 'Your token has not been verified.') {
                    resolve(await this.resolveRequest(error, {schema, variables}, false));
                }
                else {
                    reject(await this.resolveRequest(error, {schema, variables}, false));
                }
            }
        });
    }

    driverQueryPrivate(gqlSchema, variables = undefined): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const schema = gql` ${gqlSchema}`;
            try {
                const response: any = await this.onboardingClientPrivate()
                    .query({query: schema, variables: variables,});
                resolve(await this.resolveRequest(response, {schema, variables}, false));
            }
            catch (error) {
                if (getGraphqlErrorMessage(error) === 'Your token has not been verified.') {
                    resolve(await this.resolveRequest(error, {schema, variables}, false));
                }
                else {
                    reject(await this.resolveRequest(error, {schema, variables}, false));
                }
            }
        });
    }

    driverMutation(gqlSchema, variables = undefined): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const schema = gql` ${gqlSchema}`;
            try {
                const response: any = await this.onboardingClient()
                    .mutate({mutation: schema, variables: variables,});
                resolve(await this.resolveRequest(response, {schema, variables}, false));
            }
            catch (error) {
                if (getGraphqlErrorMessage(error) === 'Your token has not been verified.') {
                    resolve(await this.resolveRequest(error, {schema, variables}, false));
                }
                else {
                    reject(await this.resolveRequest(error, {schema, variables}, false));
                }
            }
        });
    }
    driverMutationPrivate(gqlSchema, variables = undefined): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const schema = gql` ${gqlSchema}`;
            try {
                const response: any = await this.onboardingClientPrivate()
                    .mutate({mutation: schema, variables: variables,});
                resolve(await this.resolveRequest(response, {schema, variables}, false));
            }
            catch (error) {
                if (getGraphqlErrorMessage(error) === 'Your token has not been verified.') {
                    resolve(await this.resolveRequest(error, {schema, variables}, false));
                }
                else {
                    reject(await this.resolveRequest(error, {schema, variables}, false));
                }
            }
        });
    }

    async resolveRequest(response, gqlSchema: GqlSchema, hasInnerToken: boolean = true) {
        if (!localStorage.getItem('jwt-driver') || getGraphqlErrorMessage(response) === 'Your token has expired.' || getGraphqlErrorMessage(response) === 'Your token has not been verified.' ) {
        //  if (getGraphqlErrorMessage(response) === 'Your token has not been verified.' || getGraphqlErrorMessage(response) === 'Your token has expired.') {
            const {data: {GetIdentificationToken: {token}}} = await this.getIdentificationToken();
            localStorage.setItem('jwt-driver', token);
            const responseAux = await this[getSchemaType(gqlSchema, hasInnerToken).client]()
                [getSchemaType(gqlSchema).functionType](
                {
                    [getSchemaType(gqlSchema).resolveType]: gql` ${gqlSchema.schema}`,
                    variables: hasInnerToken ? {token: token, ...gqlSchema.variables} : gqlSchema.variables,
                });
            return responseAux;
         }
         return response;
    }

    getIdentificationToken() {
        const mutation = gql`
            mutation($input: IdentificationTokenInput!){
                GetIdentificationToken(input: $input){
                    token
                }
            }
        `;
        return this.publicClient().mutate({mutation, variables: {input: {key: DRIVER_KEY}}});
    }
}
