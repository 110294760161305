import React, {memo, useContext, useMemo} from 'react'
import {Avatar, Col, Modal, Row} from 'antd';
import "../../../SharedPosition/styles/SharedPosition.css";
import {Button, Header,Icon} from "semantic-ui-react";
import {DriverContext, IDriverProvider} from "../../../../shared/context/DriverProvider";
import noImage from "../../../../assets/noImage.jpg"

import Spining from "../../../../shared/components/Spining";

const EndTripConfirmation = ({endTrip, loading}) => {
    const { booking, isDark, handleConfirmationModal: onCancel, openConfirmationModal: visible} = useContext<IDriverProvider>(DriverContext);
    const commonBorder = useMemo(() => isDark ? "1" : "0", [isDark]);

    return (
        <Modal
            bodyStyle={{
                background: isDark ? "black" : "white",
                borderBottom: `${commonBorder}px solid #9ca3af`,
                borderTop: `${isDark ? "0" : "0"}px  dark`,
                borderLeft: `${commonBorder}px solid #9ca3af`,
                borderRight: `${commonBorder}px solid #9ca3af`,
                borderRadius:' 0 0 12px 12px'
            }}
            closeIcon={<Icon style={{visibility: "none"}} />}
            visible={visible}
            footer={null}
            style={{top: 6}}
            title={(
                <Row className="-mb-[19px] rounded-t-2xl dark:!bg-black !px-[16px] rounded !py-[15px] dark:!border-t-[1px] dark:!border-l-[1px] dark:!border-r-[1px] dark:!border-gray-400">
                    <Col span={24}>
                        <Header as='h2'>
                            <Avatar className="mt-3" size="large" src={booking?.driver?.profile_picture || noImage} />
                            <Header.Content className="mt-[16px] ml-[10px] dark:!text-white">
                               End Trip Confirmation
                            </Header.Content>
                        </Header>
                    </Col>
                </Row>
            )}
        >
            <Spining
                loading={loading}
                size={85}
            >
            <Row className="mt-10 !text-xl dark:!text-white">
                Are you sure you want to complete this trip?
            </Row>
            <Row justify={"space-between"}>
                <Col span={12} className="mt-20 text-center pr-2">
                    <Button
                        onClick={()=>onCancel(false)}
                        className={`dark:btn-box-not-shadow !rounded-lg w-[80%] !p-[10px] !text-xl !text-white !bg-red-800`}
                    >
                        Cancel
                    </Button>
                </Col>
                <Col span={12} className="mt-20 pl-2 text-center">
                    <Button
                        onClick={endTrip}
                        className={`w-[80%] !p-[10px] !text-xl bordered dark:btn-box-not-shadow !rounded-lg !bg-dark-color !text-white`}>
                        Yes
                    </Button>
                </Col>
            </Row>
            </Spining>
        </Modal>
    );
}

export default memo(EndTripConfirmation);
