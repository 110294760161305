import React, {useCallback, useState} from 'react'
import 'react-tiny-fab/dist/styles.css';
import {Button, Icon} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {userLogoutAction} from '../../store/actions/authActions';
import { useHistory, useParams } from 'react-router-dom';


const authSelector = state => ({driverData: state.authService.driverData});

const FloatingButton = ({isRezglo}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { companyName } = useParams<{ companyName: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const { driverData } = useSelector(authSelector, shallowEqual);
   

    const handleLogout = useCallback(() => {
        setLoading(true)
        dispatch(userLogoutAction(driverData?._id, () => {
            setLoading(false);
            const withCompany= companyName ? `/${companyName}/login` : '/login'
            history.push(`${withCompany}`);
        }))
    }, [dispatch, driverData]);

    return (
        <Button 
            className={"h-[36px] w-[36px] text-center !bg-transparent"}
            text="Logout"
            onClick={handleLogout}
            type="primary"
            icon={
                <Icon 
                    name='power off' 
                    className={`pl-[3px] pt-[1px] text-[2.1rem] ${isRezglo ? "text-[#E55130]" : "text-black dark:text-white"}`} 
                    size="small" 
                    style={{  fontSize: "2rem",opacity: "unset" }}
                />        
            } 
            style={{
                boxShadow: "none",
                padding: "0px 0px 10px 0px",
                borderRadius:"50%"
                
            }}
        >
        </Button>
           
         
    )
};

FloatingButton.propTypes = {    
    isRezglo:PropTypes.bool,
};

export default FloatingButton;
