import {useCallback, useEffect, useState} from "react";
import countryModel from '../../fetch/country.model';

export const useGetCountries = (): { loading?: boolean, countries?: any [] } => {
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    const countryList = useCallback(async () => {
            try {
                setLoading(true)
                const response: any = await countryModel.publicList();
                setCountries(response?.data?.CountryList);
            } catch (e) {
                throw Error(e);
            } finally {
                setLoading(false)
            }
        },
        [],
    );

    useEffect(() => {
        countryList()
    }, [countryList])


    return {loading, countries}
}
