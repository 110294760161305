import React, {memo, useCallback, useMemo} from "react";
import SharedPosition from "../components/SharedPosition";
import DriverProvider from "../../../shared/context/DriverProvider";
import { useParams } from "react-router-dom";

export const LOAD = "loading";

const SpContainer = memo( () => {

    const firstValues = useMemo(
        () => ({
            loading: false,
        }),
        []
    );

    const reducer = useCallback((state, action) => {
        const { event, value } = action;
        return {
            ...state,
            [event]: value,
        };
    }, []);

    return (
        <DriverProvider {...{ reducer, firstValues }}>
            <SharedPosition/>
        </DriverProvider>
    );
});

export default SpContainer;
