/* eslint-disable camelcase */
import {BaseModel} from './base.model';

type BookingCompleteInputType = { _id: number | string, fleetFareType: string, actualDistance?: number | string, waitingHours?: number | string };
type BookingAcceptInputType = { tripId: number | string, driverReply: "A" | "R", field?: string, flag?: number };
type BookingCancelInputType = { tripId: number | string, field?: string };
type BookingRejectInputType = { tripId: number | string, reason?: string };
type BookingStartInputType = { tripId: number | string, status: "A" | "R", latitude: string, longitude: string };
type PaymentAfterCompleteInput = { 
  trip_id: number | string;
  payment_type: number;
  fare: string;
  actual_amount: string;
  trip_fare: string;
  promodiscount_amount?: number;
  distance: number;
  trip_type: string;
  base_fare: string;
  passenger_promo_discount?: string;
  tax_amount?: number;
  remarks?: string;
  nightfare_applicable: number;
  nightfare: string;
  eveningfare_applicable: number;
  eveningfare: string;
  waiting_time: string;
  waiting_cost: string;
  company_tax: string;
  passenger_discount: string;
  minutes_traveled: number;
  minutes_fare: string;
  fare_calculation_type: string;
  model_fare_type: number;
  creditcard_no?: string;
  expmonth?: string;
  expyear?: string;
};

class BookingModel extends BaseModel {

    getBookingById(id) {
        const schema = `
        query($id: Int!){
            BookingGet(input: $id){
              _id
              booking_id
              travel_status
              taxi {
                taxi_no
              }
              fare {
                tipFare
              }
              passenger {
                _id
                name
                lastname
                email
                country_code
                phone
                profile_image
                passengerNotes
              }
              passengerNotes
              driver {
                _id
                name
                country_code
                phone
                license_id
                profile_picture
                notes_driver
              }
              fleetFareType
              current_location
              pickup_latitude
              pickup_longitude
              approx_duration_sec
              drop_location
              drop_latitude
              drop_longitude
              scheduled_time
              pickup_time
              drop_time
              createdate
              model {
                _id
                model_name
                model_image
              }
              companyLogo
              approx_fare
              booking_from
              trip_type
              promocode
              travel_time
              approx_distance
              returnTime
              isAirportPickup
              isAirportDropoff
              airlineCodeDropoff
              flightNumberDropoff
              airlineCodePickup
              flightNumberPickup
              dispatcherNotes
              overwritePrice
              operator {
                _id
                name
                lastname
                email
              }
              farmType
              wayDirection
              companyNameType
              searchId
              availabilityId
              peopleNumber
              childrenCategory1
              childrenCategory2
              childrenCategory3
              luggage
              animalsNumber
              placeIdDropoff
              cityNamePickup
              placeIdPickup
              postalCodeDropoff
              cityCodePickup
              postalCodePickup
              cityCodeDropoff
              cityNameDropoff
              iataPickup
              iataDropoff
              distance_unit
              originalPrice
              wheelchairLiftRamp
              cancellationTime
              flightArrivalTimePickup
              flightDepartureTimePickup
              flightArrivalTimeDropoff
              flightDepartureTimeDropoff
              routesPoints {
                placeIdDropoff
                drop_latitude
                drop_longitude
                drop_location
                placeIdDropoff
              }
              hours
            }
          }
        `;
        return this.driverQueryPrivate(schema, {id});
    }

    bookingAccept(input: BookingAcceptInputType) {
        const schema = `
            mutation($input: BookingAcceptInput!){
              PublicBookingAccept(input: $input){
                _id
                travel_status
              }
            }
        `;
        return this.driverMutation(schema, {input});
    }

    bookingArrived(input: { tripId: number | string }) {
        const schema = `
            mutation($input: DriverArrivedInput!){
              DriverArrived(input: $input, franchiseIds:[]){
                message
                status
              }
            }
        `;
        return this.driverMutationPrivate(schema, {input});
    }

    bookingStart(input: BookingStartInputType) {
        const schema = `
            mutation($input: BookingStartInput!){
              BookingStart(input: $input){
                message
                status
              }
            }
        `;
        return this.driverMutationPrivate(schema, {input});
    }

    bookingComplete(input: BookingCompleteInputType) {
        const schema = `
            mutation($input: BookingCompleteInput!){
              PublicBookingComplete(input: $input, franchiseIds: []){
                _id
                status
                passenger {
                  name
                  lastName
                  email
                  phoneCode
                  phone
                }
                pickup_time
                drop_time
              }
            }
        `;
        return this.driverMutation(schema, {input});
    }

    getDriverById(id) {
        const schema = `
      query($id: Int!) {
       PublicDriverGet(_id: $id) {
          _id
          company {
            _id
          }
        }
      }
    `;
        return this.driverQuery(schema, {id});
    }

    bookingCancel(input: BookingCancelInputType) {
        const schema = `
            mutation($input: BookingDriverCancelInput!){
              BookingDriverCancel(input: $input){
                message
              }
            }
        `;
        return this.driverMutationPrivate(schema, {input});
    }

    bookingReject(input: BookingRejectInputType) {
        const schema = `
            mutation($input: BookingRejectInput!){
              PublicBookingReject(input: $input){
                message
              }
            }
        `;
        return this.driverMutation(schema, {input});
    }

    bookingTripPayment(input: PaymentAfterCompleteInput) {
      const schema = `
          mutation($input: PaymentAfterCompleteInput!){
            MakeFinalPayment(input: $input, franchiseIds: []){
              status
              message
            }
          }
      `;
      return this.driverMutationPrivate(schema, {input});
  }

  getPassengerById(id) {
      const schema = `
        query ($id: Int!) {
          PassengerGet(_id: $id) {
            isPaymentCash
            isPaymentWallet
            isPaymentCard  
            defaultCreditCard {
              id
            }
          }												
        }
  `;
      return this.driverQueryPrivate(schema, {id});
  }

}

const bookingModel = new BookingModel();
export default bookingModel;
