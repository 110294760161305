import httpService from "../config/axios/axios";
import { KOHANA_END_TRIP } from "../store/actions/endpoints";
import {openNotificationWithIcon} from "../helpers/message.helpers";

class RestAPI {

    endTripAction (booking, waitingHours, totalDistance,location=null) {
        const multiplier = booking?.distance_unit==="miles"? 0.000621371192 : 0.0001;
        const body = {
            trip_id: booking?._id,
            drop_latitude: location?.location?.lat || "--",
            drop_longitude: location?.location?.lng || "--",
            drop_location: location?.dropoffLocationName || "--",
            distance: totalDistance * multiplier || booking?.approx_distance * multiplier,
            actual_distance: totalDistance * multiplier || booking?.approx_distance * multiplier,
            waypoints: [],
            waiting_hour: waitingHours,
        }
        return httpService.post(KOHANA_END_TRIP, body)
            .then(({data}) => {
                switch (data.status) {
                    case 1:
                        openNotificationWithIcon('success', 'Rezglo Driver', `The Trip was successfully completed.`);
                        break;
                    case 4:
                        return data?.detail;
                    case -1:
                    case -8:
                    default:
                        return openNotificationWithIcon('error', 'Server Error', data.message);
                }
            })
            .catch(e => {
                return openNotificationWithIcon('error', 'Server Error', "Internal Server Error");
            });
    };


}
const restModel = new RestAPI();
export default restModel;
