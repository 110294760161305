export enum StatusTripEnum {
    SHARED_POSITION = "SHARED_POSITION",
    ARRIVED = "ARRIVED",
    START_TRIP = "START_TRIP",
    END_TRIP = "END_TRIP",
    FINISH = "FINISH",
    CANCELED = "CANCELED",
}

export enum TravelTimeType {
    BOOK_LATER = "BOOK_LATER",
    RIGHT_NOW = "RIGHT_NOW",
    OUT_TIME = "OUT_TIME",
}

export enum TravelStatusTypeEnum {
    COMPLETE = 'COMPLETE', //1
    IN_PROGRESS = 'IN_PROGRESS', //2
    CANCELLED = 'CANCELLED',
    TEST_CANCELLED = 'TEST_CANCELLED', //4
    START_TO_PICKUP = 'START_TO_PICKUP', //3
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT', //5
    REASSIGN = 'REASSIGN', //6
    WAITING_FOR_RESPONSE = 'WAITING_FOR_RESPONSE', //7
    TRIP_CONFIRMED = 'TRIP_CONFIRMED', //9
    WAITING_FOR_THE_RETURN_TRIP = 'WAITING_FOR_THE_RETURN_TRIP', //11
    ASSIGNED = 'ASSIGNED', //10
    ASSIGN = 'ASSIGN', //22
    TRIP_CANCELLED = 'TRIP_CANCELLED', //
    ALL = 'ALL', //
    CANCELLED_BY_DRIVER = 'CANCELLED_BY_DRIVER', //
}

export enum DriverShiftStatusEnum {
    IN = "IN",
    OUT = "OUT",
}

export enum TripTypeEnum {
    PER_HOUR="PER_HOUR",
    PER_MILES = "PER_MILES",
    FIXED_FARE = "FIXED_FARE"
}

