import React, {memo, useCallback, useEffect, useState} from "react";

const Chronometer = ({showMilliseconds}) => {
    const [diff, setDiff] = useState(null)
    const [initial, setInitial] = useState(null)

    const tick = useCallback(() => {
        setDiff(new Date(+new Date() - initial))
    },[setDiff, initial]);

    const start = () => {
        setInitial(+new Date())
    }

    useEffect(() => {
        if (initial) {
            requestAnimationFrame(tick);
        }
    }, [initial, tick]);

    useEffect(() => {
        if (diff) {
            requestAnimationFrame(tick);
        }
    }, [diff, tick]);

    useEffect(() => {
        start();
    }, []);

    return <>{timeFormat(diff, showMilliseconds)}</>;
}

const timeFormat = (date, showMilliseconds = false) => {
    if (!date) return "00:00:00";

    let hh: any = date.getUTCHours();
    let mm: any = date.getUTCMinutes();
    let ss: any = date.getSeconds();
    let cm: any = Math.round(date.getMilliseconds() / 10);

    hh = hh < 10 ? "0" + hh : hh;
    mm = mm < 10 ? "0" + mm : mm;
    ss = ss < 10 ? "0" + ss : ss;
    cm = cm < 10 ? "0" + cm : cm;

    return `${hh}:${mm}:${ss}${showMilliseconds ? ':' + cm : ''}`;
};

export default memo(Chronometer);

Chronometer.defaultProps = {
    showMilliseconds: false
}
