export type StrInt = string | number;

export const COMPANY_ADMIN_URL = process.env.COMPANY_ADMIN_URL;

export const fieldLimit = (field, limit) => {
    return field && field.toString().length > limit ? field.toString().substring(0, limit - 4) + '...' : field;
};

export function capitalize(s) {
    if (typeof s !== 'string') return s;
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function redirectToRezglo(url) {
    return COMPANY_ADMIN_URL+'/?redirect_to=' + url;
}

export const formatCountryCodePhone = countryCode => {
    return countryCode ? (countryCode.includes('+') ? countryCode.substr(1) : countryCode) : '1';
};

export const isNullOrUndefined = value => {
    return value === null || value === undefined;
};

export const isNullOrUndefinedOrZero = value => {
    return value === null || value === undefined || value === 0;
};

export const isNullOrUndefinedOrEmpty = value => {
    return value === null || value === undefined || value === '';
};

export const convertToBase64 = (imgPath, callback: (image: any) => void) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", imgPath, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
        console.log(this.response);
        const reader = new FileReader();
        reader.onload = function (event) {
            callback(event.target.result)
        }
        var file = this.response;
        reader.readAsDataURL(file)
    };
    xhr.send()
};

export const getKeyGraphqlResponse = <T>(data: any): T => {
    return data[Object.keys(data)[0]];
};
