import React from 'react'
import { Button, Header, Icon } from 'semantic-ui-react';
import { NumberParam, withQueryParams, } from 'use-query-params';
import { openNotificationWithIcon } from "../../../shared/helpers/message.helpers";
import rezgloImg from "../../../assets/web-Bkgd.jpg";
import rezglologo from "../../../assets/rezglologo.png";
import { DriverStatus, GeoInfoType, GoolgeType, IDriverData, LocationType } from "../../../shared/helpers/interface.helpers";
import { isEqual } from 'lodash';
import {getcompanyLogo} from "../../../shared/store/actions/authActions";
import {
    addMarker,
    calculateAndDisplayRoute,
    getInfoLocation,
    getLocationName,
    optionsPosition,
    setIconMarker,
    tryInfoPopup
} from '../../../shared/helpers/map.helpers';
import { IDriverProvider, DriverContext } from "../../../shared/context/DriverProvider";
import Spining from "../../../shared/components/Spining";
import { LOAD } from "../container/SPContainer";
import { Col, Row } from 'antd';
import "../styles/SharedPosition.css";
import moment from 'moment';
import PassengerInfo from "./PassengerInfo";
import CancellationInfo from './CancellationInfo';
import RouteVerticalLine from './RouteVerticalLine';
import TimerTrip from './TimerTrip';
import TripDetails from './TripDetails';
import { StatusTripEnum, TravelStatusTypeEnum, TravelTimeType } from '../../../shared/enums/driver.enums';
import bookingModel from "../../../shared/fetch/booking.model";
import restModel from "../../../shared/fetch/rest.model";
import Chronometer from "../../../shared/components/Chronometer";
import { timeDiff, timeDiffTrip } from "../../../shared/helpers/date.helpers";
import { RezgloIconSvg } from "../../../shared/helpers/icon.hepers";
import DriverNotes from "./DriverNotes";
import ConnectionStatus from '../../../shared/components/ConnectionStatus/ConnectionStatus';
import FloatingButton from "../../../shared/components/FloatingButton/FloatingButton";
import DriverInfo from '../../../shared/components/DriverInfo/DriverInfo';
import EndTripConfirmation from "./EndTrip/EndTripConfirmation";
import EndTripInvoice from "./EndTrip/EndTripInvoice";
import EndTripPaymentSelection from "./EndTrip/EndTripPaymentSelection";
import { TripStop } from './TripStop';
import Floatin from '../../../shared/components/ConnectionStatus/ConnectionStatus';
import profileNoimage from "../../../assets/profileNoimage.png";
import { isNullOrUndefined } from '../../../shared/helpers/string.helpers';
import { showGraphqlErrorNotifications } from "../../../shared/helpers/graphql.helpers";
declare let google: GoolgeType

interface IPassengerKeys {
    isPaymentCard: boolean;
    isPaymentWallet: boolean;   
    defaultCreditCard: object;   
}
interface IState {
    location?: LocationType;
    pickupLocation?: LocationType;
    dropoffLocationName: string;
    locationName?: string;
    estimatedDurationPU?: string;
    estimatedDurationDO?: string;
    countryName?: string;
    tripStatus?: StatusTripEnum;
    isShared?: boolean;
    driverNotesVisible?: boolean;
    map?: any;
    marker?: any;
    loading?: boolean;
    greetingSign?: boolean;
    openModalCanceled?: boolean;
    dropOffTime?: any;
    booking:any;
    companyLogo:any;
    PassengerGet: IPassengerKeys;
}

interface IProps {
    query: any;
}

class SharedPositionClass extends React.Component<IProps, IState> {
    static contextType = DriverContext;
    context!: IDriverProvider;
    interval: NodeJS.Timer;
    watchId;


    constructor(args: any) {
        super(args);
        this.state = {
            location: undefined,
            pickupLocation: undefined,
            dropoffLocationName: "",
            locationName: "",
            countryName: "",
            dropOffTime: "",
            isShared: false,
            driverNotesVisible: false,
            map: undefined,
            tripStatus: StatusTripEnum.SHARED_POSITION,
            estimatedDurationPU: "0 min",
            estimatedDurationDO: "0 min",
            marker: undefined,
            loading: false,
            greetingSign: false,
            openModalCanceled: false,
            booking:undefined,
            companyLogo:undefined,
            PassengerGet: undefined
        }
       this.interval=null;
    }

    fetchLogo = async ( ) => {       
        try {
            const logo = await getcompanyLogo("resortplus");
            this.setState({companyLogo:logo})
        } catch (error) {
            console.log(error);
        }          
    }

    getPassengerById = async (id) => {
        try {
            if (id) {
                const {data:{ PassengerGet } } = await bookingModel.getPassengerById(id);
                this.setState({ PassengerGet });                          
            }         
        } catch (error) {
            throw new Error(error);
        }
    }

    componentDidMount() {
        this.context.driverAppLogin(this.initMap);
        this.interval = setInterval(() => {
            this.successPosition(this.state.location)
          }, 25000);

          this.fetchLogo();         
    }

    getColorByStatus = status =>  isEqual(status,'ACTIVE') ? "text-[#31a697]" : "text-[#f39c12]"; 

    getNameByStatus = status => {
        if(isEqual(status,'ACTIVE')){
            return "Available";
        }
        if(isEqual(status,'IDLE')){
            return "Inactive";
        }
        return status;
    }    

    initMap = (booking: any) => {
        this.setState({ booking: booking })
        if (booking?.travel_status === TravelStatusTypeEnum.TRIP_CONFIRMED) {
            this.setState({ tripStatus: StatusTripEnum.ARRIVED })
        }
        if (booking?.travel_status === TravelStatusTypeEnum.START_TO_PICKUP) {
            this.setState({ tripStatus: StatusTripEnum.START_TRIP })
        }
        if (booking?.travel_status === TravelStatusTypeEnum.IN_PROGRESS) {
            this.setState({ tripStatus: StatusTripEnum.END_TRIP })
        }
        if (booking?.travel_status === TravelStatusTypeEnum.WAITING_FOR_PAYMENT) {
            this.setState({ tripStatus: StatusTripEnum.FINISH })
            openNotificationWithIcon('info', 'Complete Trip', "Waiting for Payment.")
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(((position: GeolocationPosition) => {
                const currentLocation: LocationType = {
                    lat: parseFloat(String(position.coords.latitude)),
                    lng: parseFloat(String(position.coords.longitude)),
                }
                this.setState({ location: currentLocation });
                const mapElement = document.getElementById('rg-shared-map');
                const color=this.context.isRezglo ? "#E55130" : "#000000";
                // @ts-ignore
                const directionsRenderer = new google.maps.DirectionsRenderer({
                    polylineOptions: { strokeColor: color },
                    suppressMarkers: true,
                });
                const map = new google.maps.Map(mapElement, {
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    streetViewControl: false,
                    zoom: 6,
                    center: currentLocation,
                })
                directionsRenderer.setMap(map);
                booking && calculateAndDisplayRoute(directionsRenderer,
                    {
                        booking,
                        currentLocation,
                        map,
                        callback: ({ estimatedDurationPU, estimatedDurationDO }) => this.setState({
                            estimatedDurationPU,
                            estimatedDurationDO
                        }),
                    });
                getInfoLocation(currentLocation, (response) => {
                    this.showInfoLocation(response);
                    const marker = addMarker(currentLocation, map, response?.locationName);
                    this.setState({ map, marker });
                    map.setCenter(currentLocation);
                     setTimeout(this.watchLocation, 200);
                });
                
            }), this.errorPosition, optionsPosition);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        navigator.geolocation.clearWatch(this.watchId)
      }

    watchLocation = () => {
        this.watchId = navigator.geolocation.watchPosition( this.successPosition, this.errorPosition, optionsPosition);
    }

    successPosition = (position) => {
        const { state: { map,tripStatus,booking }, context: { socket, driverData } } = this;
        const auxDrive={
            bookingId: driverData.bookingId,
        userid: driverData._id,
        name: driverData.name,
        phone: driverData.phone,
        company_id: driverData.company_id,
        is_subscribed_company: driverData.is_subscribed_company,
        model_name: driverData?.driver_taxi_model,
        taxi_id: driverData?.driver_taxi_number,
        shift_id:driverData.shift_id,
        companyAdminId:driverData.companyAdminId,
        }
        const statusAux: DriverStatus=socket.getDriverStatus(tripStatus);
        this.setState({ loading: false });
        const location: LocationType = {
            lat: parseFloat(position?.coords?.latitude || position?.lat),
            lng: parseFloat(position?.coords?.longitude || position?.lng),
        }
        map && map.setCenter(location);
        this.setState({ location })
        socket.sendData({ driverData:auxDrive, location, driverStatus: statusAux, },);
    }

    errorPosition = (error) => {
        const { context: { driverData } } = this;
        openNotificationWithIcon('error', 'Shared Position', error.message);
        this.context.socket.updateDriverStatus(driverData?.userid);
    }

    sendSocketData = (driverStatus: DriverStatus) => {
        const { state: { location, isShared, tripStatus }, context: { socket, setState, driverData } } = this;
        const sendData = () => {
            socket.sendData({ driverData, location, driverStatus }, () => {
                this.setState({ isShared: true });
                setState({ value: false, event: LOAD });
                !isShared && tripStatus !== StatusTripEnum.CANCELED && openNotificationWithIcon('success', 'Shared Position', 'Your position has been shared.')
            });
        }
        if (location && !socket.isConnectedDriver) socket.connect(() => sendData())
        else sendData();
    }

    acceptTrip = async () => {
        const { context: { setState, tripId, booking } } = this;
        if (tripId && (booking.travel_status !== "COMPLETE" && booking.travel_status !== "CANCELLED_BY_DRIVER")) {
            this.setState({ tripStatus: StatusTripEnum.ARRIVED })
            setState({ value: true, event: LOAD });
            try {
                await bookingModel.bookingAccept({
                    tripId,
                    driverReply: "A",
                    field: "rejection",
                    flag: 0
                });
            } catch {
                openNotificationWithIcon('error', 'Accept Trip', "Internal Server Error.")
            } finally {
                this.sendSocketData("A");
                setIconMarker(this.state.marker, "A");
                setState({ value: false, event: LOAD });
            }
        } else if (booking.travel_status === "REASSIGN" || booking.travel_status === "ASSIGNED" || booking.travel_status === "CANCELLED_BY_DRIVER") {
            this.setState({ tripStatus: StatusTripEnum.SHARED_POSITION, openModalCanceled: true });
        } else if (booking.travel_status === "COMPLETE") {
            openNotificationWithIcon('info', 'Trip Info', "Sorry, this trip is completed.")
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    arrivedTrip = async () => {
        const { context: { setState, tripId, travelTimeType, booking, setWaitingTime } } = this;
        if (travelTimeType === TravelTimeType.BOOK_LATER) {
            return openNotificationWithIcon('info', 'Arrived Trip', <>
                There are {<strong>{timeDiffTrip(moment(booking?.scheduled_time), moment())}</strong>} left for this trip to begin.
            </>);
        }
        if (tripId) {
            this.setState({ tripStatus: StatusTripEnum.START_TRIP });
            setState({ value: true, event: LOAD });
            try {
                await bookingModel.bookingArrived({ tripId });
                setWaitingTime(moment());
            } catch {
                openNotificationWithIcon('error', 'Arrived Trip', "Internal Server Error.")
            } finally {
                this.sendSocketData("A");
                setState({ value: false, event: LOAD });
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    startTrip = async () => {
        const { context: { setState, tripId, setWaitingTime, waitingTime }, state: { location } } = this;
        this.setState({ tripStatus: StatusTripEnum.END_TRIP });
        if (tripId) {
            this.setState({ dropOffTime: moment() });
            setState({ value: true, event: LOAD });
            try {
                await bookingModel.bookingStart({
                    tripId,
                    status: "A",
                    latitude: String(location?.lat),
                    longitude: String(location?.lng),
                });
                const pickupLocation = {lat: location?.lat, lng: location.lng};
                this.setState({ pickupLocation });
                setWaitingTime(moment().diff(waitingTime, 'seconds'));
                setState({ value: false, event: LOAD });
            } catch {
                openNotificationWithIcon('error', 'Start Trip', "Internal Server Error.")
                setWaitingTime(moment().diff(waitingTime, 'seconds'));
                setState({ value: false, event: LOAD });
            } finally {
                this.sendSocketData("B");
                setIconMarker(this.state.marker, "B");
                setState({ value: false, event: LOAD });
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    endTrip = async () => {
        const { context: { setState, tripId, booking, onOkConfirmationModal, waitingTime } } = this;
        this.setState({ tripStatus: StatusTripEnum.FINISH });
        if (tripId) {
            setState({ value: true, event: LOAD });
            // this.setState({dropOffTime: timeDiff(this.state.dropOffTime)})
            try {
                await bookingModel.bookingComplete({
                    _id: tripId,
                    fleetFareType: booking?.fleetFareType,
                    waitingHours: waitingTime ? waitingTime * 0.000277778 : 0,
                    actualDistance: booking?.approx_distance
                });
                onOkConfirmationModal();
                setState({ value: false, event: LOAD })
            } catch {
                openNotificationWithIcon('error', 'End Trip', "Internal Server Error.")
            } finally {
                this.sendSocketData("N");
                setIconMarker(this.state.marker, "N");
                setState({ value: false, event: LOAD })
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    endTripPerMiles = async () => {
        const { state: location, context: { setState, tripId, booking, onOkConfirmationModal, setTipDetails, waitingTime } } = this;
        this.setState({ tripStatus: StatusTripEnum.FINISH });
        if (tripId) {
            setState({ value: true, event: LOAD });
            try {
                // TODO this will be implemented at a later date
                // if (navigator.geolocation) {
                    // await navigator.geolocation.getCurrentPosition((async (position: GeolocationPosition) => {
                        await getLocationName(location?.location, (response) => {
                            this.setState({
                                dropoffLocationName: response
                            });
                        });
                        const currentLocation = new google.maps.LatLng(location?.location?.lat, location?.location?.lng);
                        const totalDistance = await google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(location?.pickupLocation?.lat, location?.pickupLocation?.lng), currentLocation);
                        const tripDetails = await restModel.endTripAction(booking, waitingTime ? waitingTime * 0.000277778 : 0, totalDistance,this.state);
                        setTipDetails(tripDetails);
                        setState({ value: false, event: LOAD })
                        this.getPassengerById(this.state.booking?.passenger?._id)
                        onOkConfirmationModal();
                    // }));
                // }
            } catch {
                openNotificationWithIcon('error', 'End Trip', "Internal Server Error.")
            } finally {
                this.sendSocketData("N");
                setIconMarker(this.state.marker, "N");
                setState({ value: false, event: LOAD })
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    endTripPayment = async (paymentMode) => {
        const { context: { setState, tripId, booking, tripDetails, setOpenInvoiceDrawer } } = this;
        const multiplier = booking?.distance_unit==="miles"? 0.000621371192 : 0.0001;       

        setState({ value: true, event: LOAD });
        if (tripId) {
            try {
                const totalDistance = tripDetails.distance;
                const { data : { MakeFinalPayment } } = await bookingModel.bookingTripPayment({ 
                        trip_id: booking?._id,
                        payment_type: paymentMode,
                        fare: tripDetails?.total_fare,
                        actual_amount: tripDetails?.total_fare,
                        trip_fare: booking?.approx_fare,
                        promodiscount_amount: Number(tripDetails?.promodiscount_amount),
                        distance: totalDistance * multiplier || booking?.approx_distance * multiplier,
                        trip_type: booking?.trip_type,
                        base_fare: `${tripDetails?.base_fare}`,
                        passenger_promo_discount: "",
                        tax_amount: tripDetails?.tax_amount,
                        remarks: "",
                        nightfare_applicable: tripDetails?.nightfare_applicable,
                        nightfare: tripDetails?.nightfare,
                        eveningfare_applicable: tripDetails?.eveningfare_applicable,
                        eveningfare: tripDetails?.eveningfare,
                        waiting_time: tripDetails?.waiting_time,
                        waiting_cost: tripDetails?.waiting_cost,
                        company_tax: tripDetails?.company_tax,
                        passenger_discount: tripDetails?.passenger_discount,
                        minutes_traveled: tripDetails?.minutes_traveled,
                        minutes_fare: tripDetails?.minutes_fare,
                        fare_calculation_type: tripDetails?.fare_calculation_type,
                        model_fare_type: tripDetails?.model_fare_type,
                        creditcard_no:"",
                        expmonth:"",
                        expyear:"",
                });

                if(!isNullOrUndefined(MakeFinalPayment)){
                  const  { status, message } = MakeFinalPayment;

                  switch (status) {
                    case 200:
                        openNotificationWithIcon('success', 'Driver Web App', message || `The Trip was successfully paid.`);
                        setState({ value: false, event: LOAD })
                        setOpenInvoiceDrawer(false);
                        break;
                    default:
                        return openNotificationWithIcon('error', 'Server Error', message);
                    }
                }
                
                
            } catch(error) {
                showGraphqlErrorNotifications(error);
                setState({ value: false, event: LOAD });
            } finally {
                this.sendSocketData("N");
                setIconMarker(this.state.marker, "N");
                setState({ value: false, event: LOAD });
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    getCurrentPosition() {
        return new Promise<GeolocationPosition>( (resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                position => resolve(position),
                error => reject(error)
            )
        })
    }

    cancelTrip = async () => {
        const { context: { setState, tripId, refreshBooking } } = this;
        this.setState({ tripStatus: StatusTripEnum.CANCELED });
        if (tripId) {
            setState({ value: true, event: LOAD });
            try {
                await bookingModel.bookingCancel({
                    tripId,
                    field: "rejection",
                });
                openNotificationWithIcon('success', 'Cancel Trip', "The Trip has been cancelled.")
            } catch {
                openNotificationWithIcon('error', 'Cancel Trip', "Internal Server Error.")
            } finally {
                this.sendSocketData("F");
                setIconMarker(this.state.marker, "F");
                refreshBooking();
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    rejectTrip = async () => {
        const { context: { setState, tripId, refreshBooking } } = this;
        this.setState({ tripStatus: StatusTripEnum.CANCELED });
        if (tripId) {
            setState({ value: true, event: LOAD });
            try {
                await bookingModel.bookingReject({
                    tripId,
                    reason: "",
                });
                openNotificationWithIcon('success', 'Decline Trip', "The Trip has been declined.")
            } catch {
                openNotificationWithIcon('error', 'Reject Trip', "Internal Server Error.")
            } finally {
                this.sendSocketData("F");
                setIconMarker(this.state.marker, "F");
                refreshBooking();
            }
        } else {
            openNotificationWithIcon('error', 'Shared Position', "Sorry, we can't get your position.")
        }
    }

    showInfoLocation = (response: GeoInfoType) => {
        if (response) {
            const { map, marker } = this.state;
            const { locationName, stateName, countryName, location } = response;
            this.setState({
                location,
                locationName: locationName,
                countryName: `${stateName}, ${countryName}`
            });
            map && map.setZoom(17);
            tryInfoPopup(map, marker, response);
        }
    }

    setTheme = () => {
        const { setTheme, isDark } = this.context;
        setTheme && setTheme(!isDark ? 'dark' : 'light');
    }

    get configTripButton() {
        const { state: { tripStatus, estimatedDurationPU, estimatedDurationDO, dropOffTime }, context: { bt_primary, bt_secondary } } = this;
        switch (tripStatus) {
            case StatusTripEnum.SHARED_POSITION:
            case StatusTripEnum.CANCELED:
                return {
                    label: "Accept Trip",
                    timerLabel: "Est. Pick-Up Time",
                    timer: estimatedDurationPU,
                    btnColor: bt_primary,
                    action: this.acceptTrip,
                }
            case StatusTripEnum.ARRIVED:
                return {
                    label: "Arrived",
                    timerLabel: "Est. Pick-Up Time",
                    btnColor: bt_primary,
                    timer: estimatedDurationPU,
                    action: this.arrivedTrip,
                }
            case StatusTripEnum.START_TRIP:
                return {
                    label: "Start Trip",
                    timerLabel: undefined,
                    timer: undefined,
                    btnColor: bt_secondary,
                    action: this.startTrip
                }
            case StatusTripEnum.END_TRIP:
                return {
                    label: "End Trip",
                    timerLabel: "Travel Time",
                    btnColor: bt_secondary,
                    timer: <Chronometer />,
                    action: () => this.context.handleConfirmationModal(true)
                }
            case StatusTripEnum.FINISH:
                const dropOffTimeStorage = localStorage.getItem("dropOffTime") ?? dropOffTime;
                if (!localStorage.getItem("dropOffTime")) localStorage.setItem('dropOffTime', dropOffTime);
                const estimatedDurationDOStorage = localStorage.getItem("estimatedDurationDO") ?? estimatedDurationDO;
                if (!localStorage.getItem("estimatedDurationDO")) localStorage.setItem('estimatedDurationDO', estimatedDurationDO);

                return {
                    label: "Thank You!",
                    timerLabel: "Drop-Off Time",
                    timer: this.isTripPerMiles ? dropOffTimeStorage : estimatedDurationDOStorage,
                    action: () => { }
                }
        }
    }

    get allowCancel() {
        const { tripStatus } = this.state;
        return tripStatus !== StatusTripEnum.FINISH && tripStatus !== StatusTripEnum.END_TRIP;
    }

    get isTripPerMiles() {
        const { booking } = this.context;
        return booking?.fleetFareType === "PER_MILES"
    }

    get allowSharedPosition() {
        const { tripStatus } = this.state;
        return tripStatus === StatusTripEnum.SHARED_POSITION || tripStatus === StatusTripEnum.CANCELED;
    }

    get isStartTrip() {
        const { tripStatus } = this.state;
        return tripStatus === StatusTripEnum.START_TRIP;
    }

    get isCancelOrDeclineTrip() {
        const { booking } = this.context;
        return booking?.travel_status === "REASSIGN" || booking?.travel_status === "COMPLETE" || booking?.travel_status === "CANCELLED_BY_DRIVER";
    }

    get isEndTrip() {
        const { tripStatus } = this.state;
        return tripStatus === StatusTripEnum.END_TRIP;
    }

    get isFinished() {
        const { tripStatus } = this.state;
        return tripStatus === StatusTripEnum.FINISH;
    }

    get showDriverNotes() {
        const { state: { tripStatus }, context: { booking } } = this;
        return (booking?.driver?.notes_driver || booking?.passengerNotes) && (tripStatus !== StatusTripEnum.SHARED_POSITION && tripStatus !== StatusTripEnum.FINISH);
    }

    get isOnTime() {
        const { booking } = this.context;
        const actualDate = moment(new Date()).format("YYYY-MM-DD");
        const pickup = moment(booking?.pickup_time).format("YYYY-MM-DD");
        return moment(actualDate).isBefore(pickup) || moment(actualDate).isSame(pickup);
    }

    render() {
        const { loadingSession, booking, isDark, state: { loading }, bt_primary, isRezglo, travelTimeType, openInvoiceDrawer, existTrip,tripId,driverData } = this.context;
        const { tripStatus, openModalCanceled, greetingSign, driverNotesVisible } = this.state;
        const isOutTimeTrip = travelTimeType === TravelTimeType.OUT_TIME;
        const isRightNowTrip = travelTimeType === TravelTimeType.RIGHT_NOW;
        const blockedSite = isOutTimeTrip && (booking?.travel_status === TravelStatusTypeEnum.ASSIGNED || booking?.travel_status === TravelStatusTypeEnum.COMPLETE);
        const isHome = tripId === undefined ? true : false;

        return (
            <Spining
                loading={loadingSession || loading}
                size={85}
            >
                <DriverInfo />
                <ConnectionStatus visible={blockedSite} existTrip={existTrip} />
                <Row className={`p-5 ${blockedSite || !existTrip ? "driver-app-blocked-site" : ""}`} align="middle">
               
                <> { !isHome &&
                    <Col span={12}>
                        <Header className="dark:text-white !text-2xl !font-bold">Ride ID: {booking?.number}</Header>
                    </Col>
                    } 

                    <Col span={!isHome ? 8 : 22} className="text-right">
                        {!isRezglo && ( 
                            <Icon
                                size="big"
                                className="cursor-pointer !text-black dark:!text-white"
                                onClick={this.setTheme}
                                name={isDark ? 'sun' : 'moon'}
                                inverted={isDark}
                            />
                        )} 
                    </Col>

                    <Col span={2}
                        className="text-right">
                            <FloatingButton isRezglo={isRezglo}/>
                    </Col>
                </>
                    {/* TODO */}
                    {/* {!isRezglo && !this.isFinished && <Col span={11}>
                        <Button
                            href={`tel:${booking?.passenger?.country_code} ${booking?.passenger?.phone}`}
                            icon
                            className={`w-full ${bt_primary} !text-white dark:!bg-dark-color dark:btn-box-not-shadow btn-box-shadow`}>
                            <Icon name="call" size="large" rotated="clockwise" />{"  "}
                            <span className="!text-xl">Make a Call</span>
                        </Button>
                    </Col>
                    } */}
                    <Col span={24} className="mt-4">
                        <Header as='h4'>
                            <div
                                className="text-xl font-bold !border-b-2 dark:text-white border-black dark:border-white">
                                  { !isHome ? 'Route Map' : 'Map'}
                            </div>
                        </Header>
                        
                        <Row id='rg-shared-map'
                            className='h-[380px] mb-5 rounded-lg dark:btn-box-not-shadow btn-box-shadow'>
                            <img src={rezgloImg}
                                className="inset-0 w-full h-full object-cover bg-gray-100 rounded-lg" />
                        </Row>
                    </Col>
                   
                    { isHome ?  
                        <Col span={24} className="flex">                                
                            {isRezglo ?
                                isDark ? <img src={rezglologo} alt="logo" className="w-[126px] mb-5" /> : <RezgloIconSvg />
                                : <img alt="logo" className="w-[126px] mb-5" src={this.state.companyLogo}/>
                            }
                        </Col>                     
                    : <>
                      {isRezglo && <Col span={11}>
                        {isDark ? <img src={rezglologo} alt="logo" className="w-[126px]" /> : <RezgloIconSvg />}
                    </Col>}
                     <Col span={isRezglo ? 13 : 24} className="text-right">
                        {this.isStartTrip &&
                            <TimerTrip timerLabel="Waiting Time" className="!mr-[10px]" count={<Chronometer />} />}
                        {!this.isStartTrip &&
                            <TimerTrip timerLabel={this.configTripButton.timerLabel} count={this.configTripButton.timer} />}
                    </Col>
                    <Col span={24} className="mt-5">
                        {this.allowSharedPosition ?
                            <Row gutter={[0, 16]}>
                                {this.isOnTime &&
                                    <Col span={24}>
                                        <Button
                                            onClick={this.configTripButton.action}
                                            size="massive"
                                            className={`dark:btn-box-not-shadow !p-[10px] !rounded-l-lg btn-box-shadow w-full h-24 !text-4xl ${this.configTripButton.btnColor} !text-white  dark:!bg-dark-color`}
                                        >
                                            {this.configTripButton.label}
                                        </Button>
                                    </Col>
                                }
                                {isRightNowTrip && this.isOnTime && <Col span={24}>
                                    <Button
                                        onClick={this.rejectTrip}
                                        size="massive"
                                        disabled={this.isCancelOrDeclineTrip}
                                        className={`dark:btn-box-not-shadow !p-[10px] ${this.isCancelOrDeclineTrip ? "!opacity-0" : ""} btn-box-shadow w-full h-24 !text-4xl !bg-red-700 !text-white !rounded-r-lg`}
                                    >
                                        Decline Trip
                                    </Button>
                                </Col>}
                            </Row>
                            :
                            <TripStop booking={booking} tripStatus={tripStatus} isTripPerMiles={this.isTripPerMiles}
                                onConfigTripButton={this.configTripButton.action} configTripButtonLabel={this.configTripButton.label}
                                configTripButtonBtnColor={this.configTripButton.btnColor} onCancelTrip={this.cancelTrip} isFinished={this.isFinished} />}
                    </Col>
                    <TripDetails booking={booking} />
                    <PassengerInfo
                        booking={booking}
                        visible={greetingSign}
                        onCancel={() => this.setState({ greetingSign: false })}
                        tripStatus={tripStatus}
                        onOpen={() => this.setState({ greetingSign: true })}
                    />
                    <CancellationInfo
                        visible={openModalCanceled}
                        onCancel={() => this.setState({ openModalCanceled: false })}
                    />
                    {this.showDriverNotes &&
                        <Col span={24} className="mt-4">
                            <DriverNotes booking={booking} visible={driverNotesVisible}
                                onCancel={() => this.setState({ driverNotesVisible: false })} />
                            <Button
                                onClick={() => this.setState({ driverNotesVisible: true })}
                                size="massive"
                                className="w-full h-20 !text-3xl !bg-[#5F5F5F] !text-white !rounded-lg"
                            >
                                Driver Notes
                            </Button>
                        </Col>
                    }</>} 
                </Row>

                {isHome && (
                    <div 
                        className='rounded-t-xl p-1rem'
                        style={{
                            boxShadow: 'grey 0px -2px 7px 4px',
                        }}
                    >
                        <Row gutter={{ xs: 12, sm: 16, md: 24, lg: 32 }}>
                         
                            <Col xs={9} sm={10} lg={9} className="flex">
                                <div className={`text-[20px] sm:text-[30px] md:text-[30px] lg:text-[25px] mt-[1rem] font-semibold pl-[1rem] ${this.getColorByStatus(driverData.status)}`}>
                                    {this.getNameByStatus(driverData.status)}                                    
                                </div>  
                            </Col>
                            <Col xs={15} sm={14} lg={15} className="flex">
                                <div className="font-bold mt-4 dark:text-white border-black dark:border-white text-[20px] sm:text-[30px] md:text-[30px] lg:text-[25px] mb-4">                        
                                    {`${driverData.name} ${driverData.lastname }` }                    
                                </div> 
                            </Col>

                            <Col xs={9} sm={10}  lg={9} className="flex items-center justify-start !pl-[1.5rem]">
                                <img 
                                    className="rounded-full avatar h-[80px] w-[80px] sm:h-[100px] sm:w-[100px] mb-5 sm:mb-0" src={driverData.profile_picture || profileNoimage} 
                                    alt="Picture" 
                                />                               
                            </Col>

                            <Col xs={15} sm={14} lg={15} className="!text-xl]">
                                <Row className="mb-[0.5rem]">
                                    <Col className="text-gray-500 font-medium">
                                        Vehicle Model:&nbsp;
                                    </Col>
                                    
                                    <Col >
                                       <span className='dark:text-white'>
                                         {driverData.driver_taxi_model}                            
                                       </span> 
                                    </Col>
                                </Row>
                                <Row className="mb-[0.5rem]">
                                    <Col className="text-gray-500 font-medium">
                                        Plate Number:&nbsp;
                                    </Col>
                                    
                                    <Col>
                                        <span className='dark:text-white'>
                                            {driverData.driver_taxi_number}                            
                                       </span> 
                                       
                                    </Col>
                                </Row>
                                <Row className="mb-[0.5rem]">
                                    <Col className="text-gray-500 font-medium">
                                        License Number:&nbsp;
                                    </Col>
                                    
                                    <Col> 
                                        <span className='dark:text-white'>
                                            {driverData.driver_license_id}                          
                                        </span>                               
                                        
                                    </Col>
                                </Row>
                                <Row className="mb-[0.5rem]">
                                    <Col className="text-gray-500 font-medium">
                                        Phone:&nbsp;
                                    </Col>
                                    
                                    <Col>
                                        <span className='dark:text-white'>
                                            {driverData.phone}                         
                                        </span>  
                                      
                                    </Col>
                                </Row>
                                <Row className="mb-[0.5rem]">
                                    <Col className="text-gray-500 font-medium">
                                        Email:&nbsp;
                                    </Col>
                                    
                                    <Col>
                                        <span className='dark:text-white'>
                                            {driverData.email}                       
                                        </span>  
                                       
                                    </Col>
                                </Row>                      
                            </Col>
                        </Row>
                    </div>
                )} 
                <EndTripConfirmation loading={loading} endTrip={booking?.fleetFareType === "PER_MILES" ? this.endTripPerMiles : this.endTrip} />
                <EndTripInvoice />
                <EndTripPaymentSelection 
                    payTrip={this.endTripPayment} 
                    loading={loading}
                    PassengerInfo={this.state.PassengerGet}                    
                />
            </Spining>
        );
    }
}

export default withQueryParams({ driverId: NumberParam, }, SharedPositionClass);
