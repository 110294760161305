import React, {memo, useContext, useState} from 'react'
import {Avatar, Col, Input, Modal, Row} from 'antd';
import "../styles/SharedPosition.css";
import {CloseIcon, PassengerIconSvg} from "../../../shared/helpers/icon.hepers";
import {Header} from "semantic-ui-react";
import {DriverContext, IDriverProvider} from "../../../shared/context/DriverProvider";
import {humanizeText} from "../../../shared/helpers/message.helpers";
import noImage from "../../../assets/noImage.jpg"

const {TextArea} = Input;

interface IProps {
    visible?: boolean;
    booking?: any;
    onCancel?: () => void;
}

const DriverNotes = (props: IProps) => {
    const {visible, onCancel, booking} = props;
    const {isDark} = useContext<IDriverProvider>(DriverContext);
    const fakeText = "No Notes Available"
    const [notesShowed, setNotesShowed] = useState(booking?.driver?.notes_driver || booking?.passengerNotes || fakeText);
    const [selected, setSelected] = useState(booking?.driver?.notes_driver?  "driver" : booking?.passengerNotes ? "passenger" : "none")

    return (
        <Modal
            bodyStyle={{
                background: isDark ? "black" : "white",
                borderBottom: `${isDark ? "1" : "0"}px solid #9ca3af`,
                borderLeft: `${isDark ? "1" : "0"}px solid #9ca3af`,
                borderRight: `${isDark ? "1" : "0"}px solid #9ca3af`,
            }}
            closeIcon={<CloseIcon/>}
            visible={visible}
            onCancel={onCancel}
            footer={null}
            style={{top: 6}}
            title={(
                <Row
                    className="-mb-[19px] dark:!bg-black !px-[16px] rounded !py-[15px] dark:!border-t-[1px] dark:!border-l-[1px] dark:!border-r-[1px] dark:!border-gray-400">
                    <Col span={24}>
                        <Header as='h2'>
                            <Avatar className="mt-3" size="large" src={booking?.driver?.profile_picture? booking?.driver?.profile_picture : noImage} />
                            <Header.Content className="mt-[6px] ml-[10px] dark:!text-white">
                                {booking?.driver?.name || "Not Available"}
                                <Header.Subheader
                                    className="text-left dark:!text-gray-300">{booking?.travel_status ? humanizeText(booking?.travel_status) : "Ongoing Now"}</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Col>
                </Row>
            )}
        >
            <Row className="mt-3" justify="center">
                <Col span={24}>
                    <h3 className="text-xl font-bold mb-2 dark:!text-white">NOTES:</h3>
                    <TextArea rows={8} value={notesShowed}
                              className="dark:!bg-black dark:!text-white"/>
                </Col>
                <Col span={24} className="mt-4">
                    <h3 className="text-lg text-gray-400 font-bold -mb-1">Notes by:</h3>
                    <Row className="mt-4">
                        {booking?.driver?.notes_driver && <Col onClick={()=> {
                            setSelected("driver");
                            setNotesShowed(booking?.driver?.notes_driver);
                        }} span={4} className="mr-1 cursor-pointer">
                            {booking?.driver?.profile_picture ?
                                <Avatar size="large" src={booking?.driver?.profile_picture}/> :
                                <PassengerIconSvg/>}
                            <h4 className={`${selected==='driver'? "dark:!text-gray-200 text-gray-600": "text-gray-400"} mt-3`}>Driver</h4>
                        </Col>}
                        {booking?.passengerNotes && <Col onClick={()=> {
                            setSelected("passenger");
                            setNotesShowed(booking?.passengerNotes);
                        }} span={8} className="mr-1 cursor-pointer">
                            {booking?.passenger?.profile_picture ?
                                <Avatar className="ml-3" size="large" src={booking?.passenger?.profile_picture}/> :
                                <PassengerIconSvg className="ml-3"/>}
                            <h4 className={`${selected==='passenger'? "dark:!text-gray-200 text-gray-600": "text-gray-400"} mt-3`}>Passenger</h4>
                        </Col>}
                    </Row>
                </Col>
                <Col span={15} className="mt-8 border-b-8 border-black dark:!border-[#5F5F5F] rounded-lg -mb-[11px]"/>
            </Row>
        </Modal>
    );
}

export default memo(DriverNotes);
